export const DefaultLocale = "en-US";
export const LocaleKey = "_locale";
export const ThemeKey = "_theme";
export const DefaultApiCLient = "default";
export const AppAuthTokenKey = "_ss01";
export const AppAuthRefeshTokenKey = "_ss02";
export const AppAuthDataKey = "_ss03";
export const AppAuthTokenExpireKey = "_ss04";
export const AppAuthTokenCreatedKey = "_ss05";
export const ExpireCookieDays = 365;
export const autoRefeshTokenDays = 7;

export const FILES_UPLOAD_ATT = "_filesUploadName";
export const FILES_DIRECTORY_ID_ATT = "fileDirectoryId";
export const OS_PLATFORM = {
  WEB: 1,
  IOS: 2,
  ANDROID: 3,
};
export const AvatarPlaceHolder128 = "https://i.pravatar.cc/128";
export const FeedSectionWidth = 300;

export const BreadcrumCategory = {
  ui: {
    collection: {
      tree: "ui.collection.tree",
    },
  },
};
