import { RouteRecordRaw } from "vue-router";
// import checkAuth from '@/utils/checkAuth';
const routes: RouteRecordRaw[] = [
  {
    path: "/dashboard",
    component: () => import("layouts/MainLayout.vue"),
    meta: { requireAuth: true },
    // beforeEnter: checkAuth,
    children: [
      { path: "", component: () => import("pages/IndexPage.vue") },
      { path: "/pinia", component: () => import("@/pages/PiniaPage.vue") },
      {
        path: "/import",
        component: () => import("@/pages/ImportBookmarks.vue"),
      },
      {
        path: "overview",
        component: () =>
          import("@/pages/Dashboard/Overview/DashboardOverview.vue"),
      },
      {
        path: "my-collection",
        component: () => import("@/pages/MyCollectionPage.vue"),
      },
      {
        path: "collection-merge-suggestion",
        component: () =>
          import(
            "@/pages/Dashboard/CollectionMergeSuggestion/CollectionMergeSuggestion.vue"
          ),
      },
      {
        path: "dead-links",
        component: () => import("@/pages/Dashboard/DeadLinks/DeadLinks.vue"),
      },
      { path: "/i18n", component: () => import("@/pages/I18nPage.vue") },
      { path: "/theme", component: () => import("@/pages/ThemePage.vue") },
      { path: "/fetch-data", component: () => import("@/pages/FetchData.vue") },
      { path: "/tutor1", component: () => import("@/pages/TutorPage1.vue") },
    ],
  },
  {
    path: "/news-feed",
    component: () => import("@/layouts/MainLayout.vue"),
    meta: { requireAuth: true },
    children: [
      { path: "", component: () => import("pages/NewsFeed/index.vue") },
      { path: "settings", component: () => import("pages/NewsFeed/NewsFeedSettings/index.vue") },
    ],
  },
  {
    path: "/feed",
    component: () => import("@/layouts/FeedLayout.vue"),
    meta: { requireAuth: true },
    children: [{ path: "", component: () => import("pages/FeedPage.vue") }],
  },
  {
    path: "/settings",
    meta: { requireAuth: true },
    component: () => import("layouts/UserSettingLayout.vue"),
    children: [
      { path: "", redirect: "/settings/pricing" },
      {
        path: "profile",
        component: () => import("@/pages/settings/ProfilePage.vue"),
      },
      {
        path: "import-export",
        component: () => import("@/pages/settings/ImportExportPage.vue"),
      },
      {
        path: "notifications",
        component: () => import("@/pages/settings/NotificationPage.vue"),
      },
      {
        path: "security",
        component: () => import("@/pages/settings/SecurityPage.vue"),
      },
      {
        path: "emails",
        component: () => import("@/pages/settings/EmailPage.vue"),
      },
      {
        path: "pricing",
        component: () => import("@/pages/settings/PricingPage/index.vue"),
      },
    ],
  },
  {
    path: "/auth-login-successful",
    component: () => import("@/pages/AuthExtLogin/AuthExtLogin.vue"),
  },
  {
    path: "/",
    component: () => import("layouts/BlankLayout.vue"),
    children: [
      { path: "", redirect: "/dashboard" },
      { path: "login", component: () => import("@/pages/LocalLogin.vue") },
      {
        path: "login2",
        component: () => import("@/pages/auth/Login2Page.vue"),
      },
      {
        path: "extension-login",
        component: () => import("@/pages/ExtensionLogin.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: () => import("pages/ErrorPage.vue"),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
  { path: "/error500", component: () => import("pages/Error500.vue") },
];

export default routes;
