/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config file > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-US.js'

import iconSet from 'quasar/icon-set/bootstrap-icons.js'



import {QBtn,QPage,QCard,QCardSection,QDrawer,QScrollArea,QList,QItem,QItemSection,QIcon,QItemLabel,QSeparator,QHeader,QSpace,QInput,QTooltip,QBadge,QAvatar,QMenu,QCardActions,QForm,QPageContainer,QLayout,QToolbarTitle,QToolbar,QExpansionItem,QImg,QCarousel,QCarouselSlide,QDialog,QSpinner,QMarkupTable,QSkeleton,QPagination,QToggle,QSelect,QPopupProxy,QDate,QField,QTabs,QRouteTab,QPageSticky,QSpinnerDots,QLinearProgress,QNoSsr,Loading,Notify,Dialog,Meta,Cookies,LoadingBar,LocalStorage} from 'quasar'



export default { config: {"loadingBar":{"color":"primary"}},lang,iconSet,components: {QBtn,QPage,QCard,QCardSection,QDrawer,QScrollArea,QList,QItem,QItemSection,QIcon,QItemLabel,QSeparator,QHeader,QSpace,QInput,QTooltip,QBadge,QAvatar,QMenu,QCardActions,QForm,QPageContainer,QLayout,QToolbarTitle,QToolbar,QExpansionItem,QImg,QCarousel,QCarouselSlide,QDialog,QSpinner,QMarkupTable,QSkeleton,QPagination,QToggle,QSelect,QPopupProxy,QDate,QField,QTabs,QRouteTab,QPageSticky,QSpinnerDots,QLinearProgress,QNoSsr},plugins: {Loading,Notify,Dialog,Meta,Cookies,LoadingBar,LocalStorage} }

