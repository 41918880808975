<template>
  <router-view />
</template>

<script setup lang="ts">
import { watch, onMounted } from "vue";
import { AppSetup } from "./utils/app";
import { useQuasar } from "quasar";
import { useLangugeAndThemeStore } from "stores/langugeAndThemeStore";
import { ITheme } from "@/types/common";
const $q = useQuasar();
const langugeAndThemeStore = useLangugeAndThemeStore();
AppSetup();

// import UnoCSS after Quasars' CSS has been imported
import "@unocss/reset/tailwind.css";
import "uno.css";

onMounted(() => {
  // if (!$q.screen.gt.xs) {
  //   langugeAndThemeStore.setLeftDrawer(false);
  // }
  window.onpopstate = () => {
    /*
    if (
      router.options.history.state.forward == "/auth/login" &&
      !authenStore.auth
    ) {
      window.history.forward();
    }
    */
    // if (
    //   authenStore.auth !== undefined &&
    //   this.$route.path == '/login'
    // ) {
    //   this.$router.push('/');
    // }
    // if (authenStore.auth === undefined) {
    //   WeeGoTo('/auth/login', true);
    // }
  };
});

const setDark = (theme: ITheme) => {
  if (theme === "dark") {
    $q.dark.set(true);
    document.documentElement.classList.add("dark");
  } else {
    $q.dark.set(false);
    document.documentElement.classList.remove("dark");
  }
};
setDark(langugeAndThemeStore.theme as ITheme);
watch(langugeAndThemeStore, (state) => {
  setDark(state.theme as ITheme);
});

watch(
  () => $q.dark.isActive,
  (val) => {
    console.log(val ? "On dark mode" : "On light mode");

    if (val) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },
);
</script>
